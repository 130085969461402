export const DealSignerABI = [
  {
    anonymous: false,
    inputs: [],
    name: 'InvestorMsgConfigDone',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nest',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'InvestorSignatureSaved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [],
    name: 'ProjectOwnerMsgConfigDone',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nest',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'ProjectOwnerSignatureSaved',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'checkInvestorSignatureExists',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'checkProjectOwnerSignatureExists',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'getInvestorMessage',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'getInvestorSignature',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'getProjectOwnerMessage',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'getProjectOwnerSignature',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'investorConfigDone',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'projectOwnerConfigDone',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature_',
        type: 'bytes',
      },
    ],
    name: 'saveInvestorSignature',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nest_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature_',
        type: 'bytes',
      },
    ],
    name: 'saveProjectOwnerSignature',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'investorMsgPart1_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'investorMsgPart2_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'investorMsgPart3_',
        type: 'string',
      },
    ],
    name: 'setupInvestorMsg',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'projectOwnerMsgPart1_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'projectOwnerMsgPart2_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'projectOwnerMsgPart3_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'projectOwnerMsgPart4_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'projectOwnerMsgPart5_',
        type: 'string',
      },
    ],
    name: 'setupProjectOwnerMsg',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
