import {
  format,
  intervalToDuration,
  formatDuration as formatDurationDateFns,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';


export const formatDateToDay = (dateInSeconds: string) =>
  format(Number(dateInSeconds) * 1000, 'dd/MM/yyyy');

export const formatDateToDayAndHour = (dateInSeconds: string) =>
  format(Number(dateInSeconds) * 1000, 'dd/MM/yyyy HH:mm');

export const formatIntervalToDuration = (seconds: number) =>
  intervalToDuration({ start: 0, end: seconds * 1000 });

export const formatDuration = (seconds: string) => {
  const duration = formatIntervalToDuration(Number(seconds));

  return formatDurationDateFns(duration);
};

export const formatDateToUtcTimezone = (date: Date) =>
  Math.floor(zonedTimeToUtc(date, 'utc').getTime() / 1000);

export const displayDateInUtcTimezone = (timestampInSeconds: string) => {
  const vestingStartDate = new Date(Number(timestampInSeconds) * 1000);

  const vestingStartDateUtc = (
    utcToZonedTime(vestingStartDate, 'utc').getTime() / 1000
  ).toString();

  return vestingStartDateUtc;
};
