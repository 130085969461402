import { ReactElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

import { Sidebar } from 'components/Sidebar';

interface Props {
  children: ReactNode;
}

export function PageLayout({ children }: Props): ReactElement {
  return (
    <Box minH="100vh" bg="gray.50">
      <Sidebar />

      <Box ml={{ base: 0, md: 60 }} p={8}>
        {children}
      </Box>
    </Box>
  );
}
