import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { avalanche, avalancheFuji } from 'viem/chains';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { isTestnet } from 'helpers/getEnvironment';

interface Props {
  children: React.ReactNode;
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [isTestnet() ? avalancheFuji : avalanche],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: process.env.REACT_APP_RPC_URL!,
      }),
    }),
  ]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

export function WagmiProvider({ children }: Props) {
  return <WagmiConfig config={config}>{children}</WagmiConfig>;
}
