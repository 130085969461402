import { ReactElement, ReactNode } from 'react';
import { Heading as ChakraHeading } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
}

export function Heading({ children }: Props): ReactElement {
  return <ChakraHeading size="sm">{children}</ChakraHeading>;
}
