import { Flex } from '@chakra-ui/react';

import { ConnectWallet } from 'components/ConnectWallet';

export function SidebarFooter() {
  return (
    <Flex px={4} py={6} justifyContent="center">
      <ConnectWallet />
    </Flex>
  );
}
