export const EarlyStageManagerABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'allocationToken',
        type: 'address',
      },
    ],
    name: 'AllocationTokenSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'analysisManager',
        type: 'address',
      },
    ],
    name: 'AnalysisManagerSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'creatorCooldownPeriod',
        type: 'uint256',
      },
    ],
    name: 'CreatorCooldownPeriodSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'dealSigner',
        type: 'address',
      },
    ],
    name: 'DealSignerSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'vestingAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'releaseDate',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokensAmount',
            type: 'uint256',
          },
        ],
        indexed: false,
        internalType: 'struct IVestingV2.DiscreteVestingRelease[]',
        name: 'vestingSchedule',
        type: 'tuple[]',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'lockedPoolDuration',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'startingFeeMantissa',
        type: 'uint256',
      },
    ],
    name: 'DiscreteVestingCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'discreteVestingFactory',
        type: 'address',
      },
    ],
    name: 'DiscreteVestingFactorySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'exchangeBoostRegistry',
        type: 'address',
      },
    ],
    name: 'ExchangeBoostRegistrySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'kyc',
        type: 'address',
      },
    ],
    name: 'KycSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'vestingAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'initialRelease',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'cliff',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'period',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockedPoolDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingFeeMantissa',
            type: 'uint256',
          },
        ],
        indexed: false,
        internalType: 'struct IVestingV2.LinearVestingParameters',
        name: 'vestingParameters',
        type: 'tuple',
      },
    ],
    name: 'LinearVestingCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'linearVestingFactory',
        type: 'address',
      },
    ],
    name: 'LinearVestingFactorySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'projectCreator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'dataURI',
        type: 'string',
      },
    ],
    name: 'ProjectCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'dataURI',
        type: 'string',
      },
    ],
    name: 'ProjectDataUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'hidden',
        type: 'bool',
      },
    ],
    name: 'ProjectHidden',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'projectNestFactory',
        type: 'address',
      },
    ],
    name: 'ProjectNestFactorySet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'enum IEarlyStageManager.Phase',
        name: 'phase',
        type: 'uint8',
      },
    ],
    name: 'ProjectPhaseUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'previousAdminRole',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'newAdminRole',
        type: 'bytes32',
      },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'staking',
        type: 'address',
      },
    ],
    name: 'StakingSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'nestAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'vestingAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'dataURI',
        type: 'string',
      },
    ],
    name: 'VestingDetailsUpdated',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FUNDRAISING_MANAGER_ROLE',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'allocationToken',
    outputs: [
      {
        internalType: 'contract ICollateralTokenV2',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'analysisManager',
    outputs: [
      {
        internalType: 'contract IAnalysisManager',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'claimCeTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'dataURI_',
        type: 'string',
      },
    ],
    name: 'createProject',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'creatorCooldownPeriod',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'creatorProjects',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creator_',
        type: 'address',
      },
    ],
    name: 'creatorProjectsLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'dealSigner',
    outputs: [
      {
        internalType: 'contract IDealSigner',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'discreteVestingFactory',
    outputs: [
      {
        internalType: 'contract IDiscreteVestingV2Factory',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'hidden',
        type: 'bool',
      },
    ],
    name: 'emitProjectHiddenFlag',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'exchangeBoostRegistry',
    outputs: [
      {
        internalType: 'contract IExchangeBoostRegistry',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature_',
        type: 'bytes',
      },
    ],
    name: 'finalizeDeal',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
    ],
    name: 'getRoleAdmin',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'getRoleMember',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
    ],
    name: 'getRoleMemberCount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'hasRole',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount_',
        type: 'uint256',
      },
    ],
    name: 'increaseAllocation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount_',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: 'signature_',
        type: 'bytes',
      },
    ],
    name: 'increaseInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'staking_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'kyc_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'projectNestFactory_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'linearVestingFactory_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'discreteVestingFactory_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'dealSigner_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'allocationToken_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'exchangeBoostRegistry_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'creatorCooldownPeriod_',
        type: 'uint256',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'raiseTarget_',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'supportedStablecoin_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'stablecoinDistributor_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'ceTokenDistributor_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'allocationParameterA_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'allocationParameterB_',
        type: 'uint256',
      },
    ],
    name: 'initializeProjectNest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'kyc',
    outputs: [
      {
        internalType: 'contract IKYC',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'linearVestingFactory',
    outputs: [
      {
        internalType: 'contract ILinearVestingV2Factory',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'contract IExchangeStrategy',
        name: 'exchangeStrategy_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'desiredAvaxAmountOut_',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'rangeShare',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rangePeriod',
            type: 'uint256',
          },
        ],
        internalType: 'struct IRewarder.DistributionRangeInput[]',
        name: 'rewardRanges_',
        type: 'tuple[]',
      },
    ],
    name: 'moveProjectToPortfolioAndCreateInitialLP',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'penalizeAccountAllocation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
    ],
    name: 'penalizeAllocation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
    ],
    name: 'projectData',
    outputs: [
      {
        components: [
          {
            internalType: 'string',
            name: 'nestDataURI',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'vestingDataURI',
            type: 'string',
          },
          {
            internalType: 'enum IEarlyStageManager.Phase',
            name: 'phase',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'createdAt',
            type: 'uint256',
          },
        ],
        internalType: 'struct IEarlyStageManager.Project',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
    ],
    name: 'projectExist',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'projectNestFactory',
    outputs: [
      {
        internalType: 'contract IProjectNestFactory',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
    ],
    name: 'projectPhase',
    outputs: [
      {
        internalType: 'enum IEarlyStageManager.Phase',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount_',
        type: 'uint256',
      },
    ],
    name: 'reduceAllocation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'refund',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'role',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'allocationToken_',
        type: 'address',
      },
    ],
    name: 'setAllocationToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'analysisManager_',
        type: 'address',
      },
    ],
    name: 'setAnalysisManager',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'creatorCooldownPeriod_',
        type: 'uint256',
      },
    ],
    name: 'setCreatorCooldownPeriod',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'dealSigner_',
        type: 'address',
      },
    ],
    name: 'setDealSigner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenPrice_',
        type: 'uint256',
      },
      {
        components: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'releaseDate',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokensAmount',
                type: 'uint256',
              },
            ],
            internalType: 'struct IVestingV2.DiscreteVestingRelease[]',
            name: 'schedule',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'startingFeeMantissa',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockedPoolDuration',
            type: 'uint256',
          },
        ],
        internalType: 'struct IVestingV2.DiscreteVestingParameters',
        name: 'vestingParameters_',
        type: 'tuple',
      },
    ],
    name: 'setDiscreteVestingData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'discreteVestingFactory_',
        type: 'address',
      },
    ],
    name: 'setDiscreteVestingFactory',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'exchangeBoostRegistry_',
        type: 'address',
      },
    ],
    name: 'setExchangeBoostRegistry',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'finalInvestment_',
        type: 'uint256',
      },
    ],
    name: 'setFinalInvestment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kyc_',
        type: 'address',
      },
    ],
    name: 'setKyc',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenPrice_',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'initialRelease',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'cliff',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'period',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockedPoolDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingFeeMantissa',
            type: 'uint256',
          },
        ],
        internalType: 'struct IVestingV2.LinearVestingParameters',
        name: 'vestingParameters_',
        type: 'tuple',
      },
    ],
    name: 'setLinearVestingData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'linearVestingFactory_',
        type: 'address',
      },
    ],
    name: 'setLinearVestingFactory',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'projectNestFactory_',
        type: 'address',
      },
    ],
    name: 'setProjectNestFactory',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'staking_',
        type: 'address',
      },
    ],
    name: 'setStaking',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'staking',
    outputs: [
      {
        internalType: 'contract IStakingCore',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'ceTokenDistributor_',
        type: 'address',
      },
    ],
    name: 'updateCeTokenDistributionStrategy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        components: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'releaseDate',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokensAmount',
                type: 'uint256',
              },
            ],
            internalType: 'struct IVestingV2.DiscreteVestingRelease[]',
            name: 'schedule',
            type: 'tuple[]',
          },
          {
            internalType: 'uint256',
            name: 'startingFeeMantissa',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockedPoolDuration',
            type: 'uint256',
          },
        ],
        internalType: 'struct IVestingV2.DiscreteVestingParameters',
        name: 'vestingParameters_',
        type: 'tuple',
      },
    ],
    name: 'updateDiscreteVestingParameters',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'startTimestamp',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'initialRelease',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'cliff',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'period',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'lockedPoolDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'startingFeeMantissa',
            type: 'uint256',
          },
        ],
        internalType: 'struct IVestingV2.LinearVestingParameters',
        name: 'vestingParameters_',
        type: 'tuple',
      },
    ],
    name: 'updateLinearVestingParameters',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'dataURI_',
        type: 'string',
      },
    ],
    name: 'updateProjectData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'enum IEarlyStageManager.Phase',
        name: 'phase_',
        type: 'uint8',
      },
    ],
    name: 'updateProjectPhase',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'raiseTarget_',
        type: 'uint256',
      },
    ],
    name: 'updateRaiseTarget',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'stablecoinDistributor_',
        type: 'address',
      },
    ],
    name: 'updateStablecoinDistributionStrategy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'nestAddress_',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'dataURI_',
        type: 'string',
      },
    ],
    name: 'updateVestingDetails',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;
