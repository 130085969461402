import { ReactElement } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';

import { shortenAddress } from 'helpers/address';

import { Modal } from '../Modal/Modal';
import { walletLogos } from './data/walletLogos';

const correctChainId = Number(process.env.REACT_APP_CHAIN_ID);

export function ConnectWallet(): ReactElement {
  // Hooks
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const { connectAsync, connectors } = useConnect();
  const { address: account, connector } = useAccount();

  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const toast = useToast({
    position: 'top',
    duration: 5000,
    isClosable: true,
  });

  // Constants
  const isWrongNetwork = chain?.id !== correctChainId;

  // Functions
  const connectWallet = async (connector: Connector) => {
    try {
      await connectAsync({
        connector,
        chainId: Number(process.env.REACT_APP_CHAIN_ID),
      });

      toast({
        title: 'We connected to your wallet successfully!',
        status: 'success',
      });
    } catch (error) {
      console.log(error);

      toast({
        title: "We couldn't connect to your wallet.",
        status: 'error',
      });
    }
  };

  // Handlers
  const handleButtonClick = () => {
    if (isWrongNetwork) {
      try {
        switchNetwork?.(correctChainId);
      } catch (error) {
        console.log(error);

        toast({
          title: 'Something went wrong',
          status: 'error',
        });
      }
    } else {
      disconnect();
    }
  };

  const handleConnectWallet = async (connector: Connector) => {
    onCloseModal();

    await connectWallet(connector);
  };

  return (
    <>
      {!account ? (
        <Button variant="confirm" onClick={onOpenModal}>
          Connect Wallet
        </Button>
      ) : (
        <Stack w="full" spacing={5}>
          {!isWrongNetwork && (
            <Flex alignItems="center" gap={3.5}>
              <Box p={2} w="fit-content" borderRadius="xl" bgColor="pink.100">
                <img
                  src={walletLogos[connector?.id || '']}
                  alt="Account"
                  width="40px"
                />
              </Box>

              <Flex flexDir="column" fontWeight="semibold">
                <Text color="gray.900">{shortenAddress(account)}</Text>
                <Text color="gray.500" fontSize="sm">
                  Admin
                </Text>
              </Flex>
            </Flex>
          )}
          <Button
            variant={isWrongNetwork ? 'error' : 'neutral'}
            onClick={handleButtonClick}
          >
            {isWrongNetwork ? 'Switch to correct network' : 'Disconnect'}
          </Button>
        </Stack>
      )}

      <Modal
        header="Connect wallet"
        isOpen={isModalOpen}
        onClose={onCloseModal}
      >
        <SimpleGrid columns={2} spacing={10}>
          {connectors.map((connector) => (
            <VStack
              key={connector.id}
              py={10}
              w="full"
              spacing={4}
              borderRadius="xl"
              _hover={{ cursor: 'pointer', bgColor: 'gray.100', opacity: 0.9 }}
              _active={{ cursor: 'pointer', bgColor: 'gray.100', opacity: 0.7 }}
              _focus={{ boxShadow: 'none' }}
              onClick={() => handleConnectWallet(connector)}
            >
              <img
                src={walletLogos[connector.id]}
                alt="Account"
                width="100px"
              />
              <Text fontSize="2xl" fontWeight="semibold">
                {connector.name}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      </Modal>
    </>
  );
}
