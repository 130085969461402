import { ReactElement } from 'react';
import { IconType } from 'react-icons';

import { Routes } from 'routes';

export interface SidebarLink {
  name: string;
  path: string;
  icon: IconType;
  component: ReactElement;
}

export const sidebarLinks: SidebarLink[] = [];
