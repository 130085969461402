import { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';

import { sidebarLinks } from '../data';
import { SidebarLink } from './SidebarLink';

export function SidebarLinks(): ReactElement {
  return (
    <VStack spacing={3}>
      {sidebarLinks.map(({ name, icon, path }) => (
        <SidebarLink key={name} icon={icon} path={path}>
          {name}
        </SidebarLink>
      ))}
    </VStack>
  );
}
