import { FiMenu } from 'react-icons/fi';
import { Text, Flex, FlexProps, IconButton } from '@chakra-ui/react';

interface Props extends FlexProps {
  onOpen: () => void;
}
export function SidebarMobile({ onOpen, ...rest }: Props) {
  return (
    <Flex
      bg="white"
      height={20}
      alignItems="center"
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      borderBottomWidth="1px"
      justifyContent="flex-start"
      borderBottomColor="gray.200"
      {...rest}
    >
      <IconButton
        onClick={onOpen}
        variant="outline"
        icon={<FiMenu />}
        aria-label="open menu"
      />

      <Text fontSize="2xl" ml={8} fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
}
