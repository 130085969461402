import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import { Button } from './components/Button';
import { globalStyles } from './globalStyles';
import { breakpoints, colors } from './foundations';

export default extendTheme({
  colors,
  breakpoints,
  styles: globalStyles,
  components: {
    Steps,
    Button,
  },
  fonts: {
    body: "'Nunito', sans-serif",
  }
});
