import { ReactElement, ReactNode } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { Heading } from 'components/Heading';

interface Props {
  title: string | ReactElement | ReactNode;
  content: string | undefined | null;
}

export function InfoSectionWithText({ title, content }: Props): ReactElement {
  return (
    <Flex flexDir="column" gap={2}>
      <Heading>{title}</Heading>
      <Text textTransform="capitalize">{content || '-'}</Text>
    </Flex>
  );
}
