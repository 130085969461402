import { mathjs } from './math';

export const isLessThan = (firstValue: string, secondValue: string) =>
  mathjs.bignumber(firstValue).lessThan(mathjs.bignumber(secondValue));

export const isLessThanOrEqual = (firstValue: string, secondValue: string) =>
  mathjs.bignumber(firstValue).lessThanOrEqualTo(mathjs.bignumber(secondValue));

export const isGreaterThan = (firstValue: string, secondValue: string) =>
  mathjs.bignumber(firstValue).greaterThan(mathjs.bignumber(secondValue));

export const isGreaterThanOrEqual = (firstValue: string, secondValue: string) =>
  mathjs
    .bignumber(firstValue)
    .greaterThanOrEqualTo(mathjs.bignumber(secondValue));

export const isGreaterThanZero = (value: string) =>
  mathjs.bignumber(value).greaterThan(0);

export const multiplyBigNumbers = (firstValue: string, secondValue: string) =>
  mathjs
    .chain(mathjs.bignumber(firstValue))
    .multiply(mathjs.bignumber(secondValue))
    .format({ notation: 'fixed' })
    .done();

export const divideBigNumbers = (firstValue: string, secondValue: string) =>
  mathjs
    .chain(mathjs.bignumber(firstValue))
    .divide(mathjs.bignumber(secondValue))
    .format({ notation: 'fixed' })
    .done();

export const toPercentages = (value: string) =>
  mathjs
    .chain(mathjs.bignumber(value))
    .multiply(mathjs.pow(10, 2))
    .format({ notation: 'fixed' })
    .done();
