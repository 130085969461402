import { ReactElement } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { SidebarContent, SidebarMobile } from './components';

export function Sidebar(): ReactElement {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SidebarContent display={{ base: 'none', md: 'flex' }} />
      <Drawer
        size="xs"
        isOpen={isOpen}
        placement="left"
        autoFocus={false}
        onClose={onClose}
        onOverlayClick={onClose}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            zIndex="3"
            onClick={onClose}
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />

          <SidebarContent />
        </DrawerContent>
      </Drawer>

      <SidebarMobile display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
    </>
  );
}
