import { ReactElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
}

export function Card({ children }: Props): ReactElement {
  return (
    <Box py={8} mt={9} px={10} w="100%" bgColor="white" borderRadius="lg">
      {children}
    </Box>
  );
}
