export const Button = {
  baseStyle: {
    _disabled: {
      opacity: 1,
      bg: 'gray.300',
    },
    _hover: {
      _disabled: {
        opacity: 1,
        bg: 'gray.300',
      },
    },
  },
  sizes: {},
  variants: {
    primary: {
      bg: 'green.500',
      color: 'white',
      _hover: {
        opacity: 0.9,
      },
      _active: { opacity: 0.7 },
      _focus: { boxShadow: 'none' },
    },
    secondary: {
      bg: 'purple.500',
      color: 'white',
      _hover: {
        opacity: 0.9,
      },
      _active: { opacity: 0.7 },
      _focus: { boxShadow: 'none' },
    },
    edit: {
      bg: 'blue.50',
      color: 'blue.500',
      _hover: {
        opacity: 0.75,
      },
      _active: { opacity: 1 },
      _focus: { boxShadow: 'none' },
    },
    confirm: {
      bg: 'blue.500',
      color: 'white',
      _hover: {
        opacity: 0.9,
      },
      _active: { opacity: 0.7 },
      _focus: { boxShadow: 'none' },
    },
    neutral: {
      bg: 'gray.200',
      color: 'black',
      _hover: {
        opacity: 0.7,
      },
      _active: { opacity: 0.7 },
      _focus: { boxShadow: 'none' },
    },
    error: {
      bg: 'red.500',
      color: 'white',
      _hover: {
        opacity: 0.8,
      },
      _active: { opacity: 0.7 },
      _focus: { boxShadow: 'none' },
    },
  },
  defaultProps: {},
};
