import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Flex, Spinner, Stack, Tooltip } from '@chakra-ui/react';
import { signMessage } from '@wagmi/core';
import { fromHex, parseEther } from 'viem';
import { useAccount } from 'wagmi';

import srcColonyMark from 'assets/images/colony-mark.svg';
import { ApprovalState, checkApproval } from 'helpers/approval';
import {
  displayDateInUtcTimezone,
  formatDateToDayAndHour,
  formatDuration,
} from 'helpers/date';
import {
  DealSignerABI,
  ERC20TokenABI,
  ProjectNestABI,
  StablecoinDistributorABI,
} from 'ethereum/abi';
import {
  DealSignerContract,
  EarlyStageManagerContract,
} from 'ethereum/contracts';
import {
  addDecimals,
  subtractDecimals,
  toLocaleStringWithCustomDecimals,
} from 'helpers/format';
import { useContractFunction, useProject } from 'hooks';
import { isLessThan, multiplyBigNumbers } from 'helpers/numbers';
import { TxStep, TX_ERRORS, TX_STATUS } from 'hooks/useContractFunction';

import { Card } from 'components/Card';
import { InfoSectionWithList } from 'components/InfoSectionWithList';
import { InfoSectionWithText } from 'components/InfoSectionWithText';
import { TxModal } from 'components/Modal/TxModal';

export function NestPublicDealPage(): ReactElement {
  // State
  const [allowance, setAllowance] = useState('0');
  const [isTxModalOpen, setIsTxModalOpen] = useState(false);
  const [isDealLoading, setIsDealLoading] = useState(false);
  const [isDealDataLoading, setIsDealDataLoading] = useState(false);
  const [isDealFinalized, setIsDealFinalized] = useState(false);
  const [projectTokenBalance, setProjectTokenBalance] = useState('0');
  const [projectCreatorShare, setProjectCreatorShare] = useState('0');
  const [txStatus, setTxStatus] = useState<TxStep>(TX_STATUS.APPROVE_TOKEN);
  const [dealData, setDealData] = useState<any>({
    nestId: '',
    phaseId: '',
    tokenSymbol: '',
    tokenAmount: '0',
    tokenAddress: '',
    tokenDecimals: '18',
    vestingId: '',
    vestingType: '',
    vestingCliff: '0',
    vestingPeriod: '0',
    vestingStartDate: '0',
    vestingInitialRelease: '0',
    nestStablecoinSymbol: '',
    provideTokens: '0',
    receiveFinalInvestment: '0',
  });

  // Hooks
  const { address: account, isConnected } = useAccount();
  const { project, isLoading, fetchProject } = useProject();

  const { isTxSuccess, runContractRead, runContractWrite } =
    useContractFunction();

  // Contract functions
  const getProjectCreatorShare = useCallback(async () => {
    if (dealData.nestId) {
      setIsDealDataLoading(true);

      const stablecoinDistributorFunctionConfig = {
        address: dealData.nestId,
        abi: ProjectNestABI,
        functionName: 'stablecoinDistributor',
        args: [],
      };

      const stablecoinDistributorAddress = await runContractRead(
        stablecoinDistributorFunctionConfig
      );

      if (stablecoinDistributorAddress) {
        const projectCreatorShareFunctionConfig = {
          address: stablecoinDistributorAddress,
          abi: StablecoinDistributorABI,
          functionName: 'projectCreatorShare',
          args: [],
        };

        const projectCreatorShare = await runContractRead(
          projectCreatorShareFunctionConfig
        );

        const formattedProjectCreatorShare = subtractDecimals(
          (projectCreatorShare as any).toString(),
          18
        );

        setProjectCreatorShare(formattedProjectCreatorShare);
        setIsDealDataLoading(false);
      }
    }
  }, [dealData.nestId, runContractRead]);

  const getProjectTokenBalance = useCallback(async () => {
    if (dealData.tokenAddress && account) {
      const functionConfig = {
        address: dealData.tokenAddress,
        abi: ERC20TokenABI,
        functionName: 'balanceOf',
        args: [account as any],
      };

      const projectTokenBalance = await runContractRead(functionConfig);

      setProjectTokenBalance(projectTokenBalance?.toString() || '0');
    }
  }, [account, dealData.tokenAddress, runContractRead]);

  const getAllowance = useCallback(async () => {
    if (account && dealData.tokenAddress && dealData.nestId) {
      const functionConfig = {
        address: dealData.tokenAddress,
        abi: ERC20TokenABI,
        functionName: 'allowance',
        args: [account, dealData.nestId],
      };

      const allowance = await runContractRead(functionConfig);

      setAllowance(allowance?.toString() || '0');
    }
  }, [account, dealData.tokenAddress, runContractRead]);

  const getIsDealFinalized = useCallback(async () => {
    if (dealData.nestId) {
      const functionConfig = {
        address: dealData.nestId,
        abi: ProjectNestABI,
        functionName: 'dealFinalized',
        args: [],
      };

      const isDealFinalized = await runContractRead(functionConfig);

      setIsDealFinalized(isDealFinalized as any);
    }
  }, [dealData.nestId, runContractRead]);

  const approveToken = useCallback(
    async (amount: BigInt) => {
      if (dealData.tokenAddress && dealData.nestId) {
        const functionConfig = {
          address: dealData.tokenAddress,
          abi: ERC20TokenABI,
          functionName: 'approve',
          args: [dealData.nestId, amount],
        };

        await runContractWrite(functionConfig);
      }
    },
    [dealData.tokenAddress, runContractWrite]
  );

  const finalizeDeal = useCallback(async () => {
    if (dealData.nestId) {
      setTxStatus(TX_STATUS.DEAL_SIGNER);

      const bytesMessage: unknown = await runContractRead({
        address: DealSignerContract.address,
        abi: DealSignerABI,
        functionName: 'getProjectOwnerMessage',
        args: [dealData.nestId, account],
      });

      const ownerMessage = fromHex(bytesMessage as `0x${string}`, 'string');

      const ownerSignatureExists: unknown = await runContractRead({
        address: DealSignerContract.address,
        abi: DealSignerABI,
        functionName: 'checkProjectOwnerSignatureExists',
        args: [dealData.nestId, account],
      });

      if ((ownerSignatureExists as boolean) !== true) {
        const signature = await signMessage({
          message: ownerMessage,
        });

        await runContractWrite({
          address: DealSignerContract.address,
          abi: DealSignerABI,
          functionName: 'saveProjectOwnerSignature',
          args: [dealData.nestId, account, signature],
        });
      }

      const ownerSignature = await runContractRead({
        address: DealSignerContract.address,
        abi: DealSignerABI,
        functionName: 'getProjectOwnerSignature',
        args: [dealData.nestId, account],
      });

      setTxStatus(TX_STATUS.CONFIRM);

      const functionConfig = {
        ...EarlyStageManagerContract,
        functionName: 'finalizeDeal',
        args: [dealData.nestId, ownerSignature],
      };

      await runContractWrite(functionConfig);
    }
  }, [account, dealData.nestId, runContractRead, runContractWrite]);

  // Functions
  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  const getDealProjectData = useCallback(() => {
    if (project && !isEmpty(project)) {
      const {
        id,
        token,
        vesting,
        tokenAmount,
        nestStablecoin,
        nestFinalInvestment,
      } = project;

      let provideTokens;
      let receiveFinalInvestment;
      let vestingData = {};
      let tokenData = {
        tokenSymbol: '',
        tokenAddress: '',
        tokenDecimals: '18',
      };

      if (nestFinalInvestment && tokenAmount) {
        const formattedFinalInvestment = subtractDecimals(
          nestFinalInvestment,
          Number(nestStablecoin?.decimals)
        );

        receiveFinalInvestment = multiplyBigNumbers(
          formattedFinalInvestment,
          projectCreatorShare
        );

        provideTokens = subtractDecimals(tokenAmount, Number(token?.decimals));
      }

      if (vesting) {
        vestingData = {
          vestingId: vesting.id,
          vestingType: vesting.type,
          vestingCliff: vesting.cliff,
          vestingPeriod: vesting.vestingPeriod,
          vestingStartDate: vesting.startTimestamp,
          vestingSchedule: vesting.discreteSchedule,
          vestingInitialRelease: subtractDecimals(
            vesting?.initialRelease,
            Number(token?.decimals)
          ),
        };
      }

      if (token) {
        tokenData = {
          tokenAddress: token.id,
          tokenSymbol: token.symbol,
          tokenDecimals: token.decimals,
        };
      }

      const data = {
        nestId: id,
        nestStablecoinSymbol: nestStablecoin?.symbol,
        provideTokens: provideTokens || '0',
        receiveFinalInvestment: receiveFinalInvestment || '0',
        ...tokenData,
        ...vestingData,
        ...project,
      };

      setDealData(data);
    }
  }, [project, projectCreatorShare]);

  // Handlers
  const handleCloseTxModal = useCallback(() => {
    setIsTxModalOpen(false);
  }, []);

  const handleCompleteDeal = useCallback(async () => {
    setIsDealLoading(true);
    setIsTxModalOpen(true);
    setTxStatus(TX_STATUS.APPROVE_TOKEN);

    const approvalStatus = checkApproval(
      parseEther(dealData.provideTokens),
      allowance
    );

    try {
      if (approvalStatus === ApprovalState.TO_BE_RESET) {
        await approveToken(parseEther('0'));

        await approveToken(parseEther(dealData.provideTokens));

        await getAllowance();
      } else if (approvalStatus === ApprovalState.TO_BE_APPROVED) {
        await approveToken(parseEther(dealData.provideTokens));

        await getAllowance();
      }

      await finalizeDeal();

      setTxStatus(TX_STATUS.SUCCESS);
    } catch (error) {
      if (error === TX_ERRORS.CANCELED_BY_USER) {
        setTxStatus(TX_STATUS.CANCELED);
      } else {
        setTxStatus(TX_STATUS.ERROR);
      }
    }

    setIsDealLoading(false);
  }, [allowance, approveToken, dealData, finalizeDeal, setTxStatus]);

  // Constants
  const isButtonDisabled = useMemo(() => {
    if (isLoading || isDealLoading || isDealFinalized) {
      return true;
    }

    if (projectTokenBalance === '0') {
      return true;
    }

    if (
      isLessThan(
        projectTokenBalance,
        addDecimals(dealData.provideTokens, Number(dealData.tokenDecimals))
      )
    ) {
      return true;
    }

    return false;
  }, [
    dealData,
    isDealFinalized,
    isDealLoading,
    isLoading,
    projectTokenBalance,
  ]);

  // Effects
  useEffect(() => {
    if (isConnected) {
      getProjectTokenBalance();
      getAllowance();
      getIsDealFinalized();
      getProjectCreatorShare();
    }
  }, [
    getAllowance,
    getIsDealFinalized,
    getProjectCreatorShare,
    getProjectTokenBalance,
    isConnected,
  ]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    getDealProjectData();
  }, [project, getDealProjectData]);

  // Effects
  useEffect(() => {
    if (isTxSuccess) {
      fetchProject();
      getIsDealFinalized();
      setIsDealLoading(false);
    }
  }, [isTxSuccess, fetchProject, getIsDealFinalized]);

  const displayDealData = useCallback(() => {
    if (isDealDataLoading) {
      return (
        <Card>
          <Flex justifyContent="center">
            <Spinner size="lg" />
          </Flex>
        </Card>
      );
    }

    if (project && !isEmpty(project) && project?.phaseId === '[p4]') {
      return (
        <Card>
          <Flex mt={10} gap={24} justifyContent="center">
            <Stack spacing={8}>
              <InfoSectionWithText
                title="Project will receive"
                content={`${toLocaleStringWithCustomDecimals(
                  dealData?.receiveFinalInvestment,
                  0,
                  4
                )} ${dealData?.nestStablecoinSymbol}`}
              />

              <InfoSectionWithText
                title="Project has to provide"
                content={`${toLocaleStringWithCustomDecimals(
                  dealData?.provideTokens,
                  0,
                  4
                )} ${dealData?.tokenSymbol} `}
              />
            </Stack>

            <Box w="1px" border="1px solid" borderColor="gray.200" />

            <Stack spacing={8}>
              <InfoSectionWithText
                title="Vesting type"
                content={dealData?.vestingType}
              />

              {dealData?.vestingType === 'linear' ? (
                <>
                  <InfoSectionWithText
                    title="Vesting start date"
                    content={`${formatDateToDayAndHour(
                      displayDateInUtcTimezone(
                        dealData?.vestingStartDate
                      ) as string
                    )} UTC`}
                  />
                  <InfoSectionWithText
                    title="Initial release"
                    content={`${toLocaleStringWithCustomDecimals(
                      dealData?.vestingInitialRelease,
                      0,
                      4
                    )} ${dealData?.tokenSymbol}`}
                  />
                  <InfoSectionWithText
                    title="Cliff"
                    content={formatDuration(dealData?.vestingCliff)}
                  />
                  <InfoSectionWithText
                    title="Vesting period"
                    content={formatDuration(dealData?.vestingPeriod)}
                  />
                </>
              ) : (
                <InfoSectionWithList
                  title="Schedule"
                  tokenSymbol={dealData?.token?.symbol}
                  tokenDecimals={dealData?.token?.decimals}
                  vestingSchedule={dealData?.vestingSchedule}
                />
              )}
            </Stack>
          </Flex>

          <Flex mt={20} justifyContent="center">
            <Tooltip
              placement="top"
              isDisabled={!isButtonDisabled}
              label="You don't have required amount of tokens or deal is not possible."
            >
              <div>
                <Button
                  py={8}
                  px={16}
                  variant="primary"
                  onClick={handleCompleteDeal}
                  disabled={isButtonDisabled}
                >
                  Complete the deal
                </Button>

                {!isConnected && !isDealFinalized ? (
                  <div style={{ margin: '12px 4px' }}>
                    <strong>Connect your wallet to complete the deal.</strong>
                  </div>
                ) : null}

                {isDealFinalized ? (
                  <div style={{ margin: '12px 4px' }}>
                    <strong>The deal has already been completed.</strong>
                  </div>
                ) : null}
              </div>
            </Tooltip>
          </Flex>
        </Card>
      );
    }

    return null;
  }, [
    dealData,
    handleCompleteDeal,
    isButtonDisabled,
    isConnected,
    isDealDataLoading,
    isDealFinalized,
    project,
  ]);

  return (
    <>
      <Flex mt={10} justifyContent="center" alignItems="center">
        <img src={srcColonyMark} alt="" style={{ height: '150px' }} />

        <h1 style={{ margin: '0 16px', fontSize: '60px', fontWeight: 600 }}>
          Colony
        </h1>
      </Flex>

      {!project || isEmpty(project) ? <div>Project not found.</div> : null}

      {project && !isEmpty(project) && project?.phaseId !== '[p4]' ? (
        <div>Project is not in Investment Committe phase.</div>
      ) : null}

      {displayDealData()}

      <TxModal
        open={isTxModalOpen}
        onClose={handleCloseTxModal}
        transactionStatus={txStatus}
        isLoading={isDealLoading}
      />
    </>
  );
}
