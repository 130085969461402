import { isGreaterThanZero, isLessThan, isLessThanOrEqual } from './numbers';

export const ApprovalState = {
  APPROVED: 'APPROVED',
  TO_BE_RESET: 'TO_BE_RESET',
  TO_BE_APPROVED: 'TO_BE_APPROVED',
};

export function checkApproval(amount: BigInt, allowance: string) {
  if (isLessThanOrEqual(amount.toString(), allowance)) {
    return ApprovalState.APPROVED;
  }

  if (
    isGreaterThanZero(allowance) &&
    isLessThan(allowance, amount.toString())
  ) {
    return ApprovalState.TO_BE_RESET;
  }

  return ApprovalState.TO_BE_APPROVED;
}
