import { DealSignerABI, EarlyStageManagerABI } from './abi';

export const DealSignerContract = {
  abi: DealSignerABI,
  address: process.env.REACT_APP_DEAL_SIGNER_CONTRACT || '',
};

export const EarlyStageManagerContract = {
  abi: EarlyStageManagerABI,
  address: process.env.REACT_APP_EARLY_STAGE_MANAGER_CONTRACT || '',
};
