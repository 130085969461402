import { graphClient } from 'api/providers/graph';
import ProjectQUERY, {
  ProjectResult,
  ProjectVariables,
} from 'api/providers/graph/queries/getProject.query';

import { BlockstampedData } from 'api/providers/graph/types';

export async function graphGetProject(
  id: ProjectVariables['id']
): Promise<BlockstampedData<any>> {
  const data = await graphClient.request<ProjectResult, ProjectVariables>(
    ProjectQUERY,
    {
      id,
    }
  );

  const project = {
    ...data.project,
  };

  return {
    // eslint-disable-next-line
    block: data._meta.block.number,
    data: project,
  };
}
