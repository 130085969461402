import { ReactElement, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

import { Heading } from 'components/Heading';
import { subtractDecimals } from 'helpers/format';
import { formatDateToDayAndHour, displayDateInUtcTimezone } from 'helpers/date';

interface Props {
  tokenSymbol: string;
  tokenDecimals: string;
  title: string | ReactElement | ReactNode;
  vestingSchedule: { timestamp: string; releaseAmount: string }[];
}

export function InfoSectionWithList({
  title,
  tokenSymbol,
  tokenDecimals,
  vestingSchedule,
}: Props): ReactElement {
  return (
    <Flex flexDir="column" gap={2}>
      <Heading>{title}</Heading>
      <div>
        {vestingSchedule
          ? vestingSchedule.map(({ timestamp, releaseAmount }) => (
              <div key={timestamp}>
                <span>{`${subtractDecimals(
                  releaseAmount,
                  Number(tokenDecimals)
                )} ${tokenSymbol}`}</span>
                {' - '}
                <span>
                  {`${formatDateToDayAndHour(
                    displayDateInUtcTimezone(
                      String(Number(timestamp) * 1000)
                    ) as string
                  )}
                 UTC`}
                </span>
              </div>
            ))
          : '-'}
      </div>
    </Flex>
  );
}
