import srcMetamaskLogo from 'assets/images/metamask-logo.svg';
import srcWalletConnectLogo from 'assets/images/walletconnect-logo.svg';

const ConnectorsIds = {
  METAMASK: 'metaMask',
  WALLET_CONNECT: 'walletConnect',
};

export const walletLogos = {
  [ConnectorsIds.METAMASK]: srcMetamaskLogo,
  [ConnectorsIds.WALLET_CONNECT]: srcWalletConnectLogo,
};
