import { ReactElement } from 'react';

import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { TxStep } from 'hooks/useContractFunction';

import { TxStepper } from 'components/TxStepper/TxStepper';

export interface Props {
  open: boolean;
  isLoading: boolean;
  transactionStatus: TxStep;
  onClose(): void;
}

export function TxModal({
  open,
  isLoading,
  transactionStatus,
  onClose,
}: Props): ReactElement {
  return (
    <Modal
      isCentered
      size="sm"
      isOpen={open}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Transaction Process</ModalHeader>
        <ModalCloseButton />

        <TxStepper transactionStatus={transactionStatus} />
      </ModalContent>
    </Modal>
  );
}
