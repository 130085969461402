import { ReactElement } from 'react';

import { NestPublicDealPage } from 'pages/NestPublicDealPage';

export interface Route {
  name: string;
  path: string;
  component: ReactElement;
}

export enum Routes {
  NEST_PUBLIC_DEAL = '/complete',
}

export enum SearchParams {
  PROJECT_ID = 'projectId',
}

export const routes: Route[] = [
  {
    name: 'NEST Public Deal',
    path: Routes.NEST_PUBLIC_DEAL,
    component: <NestPublicDealPage />,
  },
];
