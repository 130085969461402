import { gql } from 'graphql-request';

import { ID, _Block_, BigInt } from '../types';

export interface ProjectResult {
  _meta: {
    block: {
      number: _Block_['number'];
    };
  };
  project: {
    id: ID;
    name: string;
    phaseId: string;
    tokenAmount: BigInt;
    dealFinalized: boolean;
    nestFinalInvestment: BigInt;
    token: {
      id: ID;
      name: string;
      symbol: string;
      decimals: BigInt;
    };
    nestStablecoin: {
      id: ID;
      name: string;
      symbol: string;
      decimals: BigInt;
    };
    vesting: {
      id: ID;
      type: string;
      cliff: BigInt;
      vestingPeriod: BigInt;
      initialRelease: BigInt;
      startTimestamp: BigInt;
      discreteSchedule: {
        timestamp: BigInt;
        releaseAmount: BigInt;
      }[];
    };
  };
}

export interface ProjectVariables {
  id: string;
}

const ProjectQUERY = gql`
  query getProject($id: String!) {
    _meta {
      block {
        number
      }
    }

    project(id: $id) {
      id
      name
      phaseId
      tokenAmount
      dealFinalized
      nestFinalInvestment
      token {
        id
        name
        symbol
        decimals
      }
      nestStablecoin {
        id
        name
        symbol
        decimals
      }
      vesting {
        id
        type
        cliff
        vestingPeriod
        initialRelease
        startTimestamp
        discreteSchedule {
          timestamp
          releaseAmount
        }
      }
    }
  }
`;

export default ProjectQUERY;
