import { ReactElement, ReactNode } from 'react';
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Modal as ChakraModal,
} from '@chakra-ui/react';

interface Props {
  header: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  confirmButtonText?: string;
  declineButtonText?: string;
  onConfirm?(): void;
  onDecline?(): void;
}

export function Modal({
  header,
  children,
  isOpen,
  onClose,
  confirmButtonText,
  declineButtonText,
  onConfirm,
  onDecline,
}: Props): ReactElement {
  // Handlers
  const handleConfirmButtonClick = () => {
    onClose();

    onConfirm && onConfirm();
  };

  const handleDeclineButtonClick = () => {
    onClose();

    onDecline && onDecline();
  };

  return (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="50vw">
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter mt={6}>
          {declineButtonText && (
            <Button variant="error" onClick={handleDeclineButtonClick}>
              {declineButtonText}
            </Button>
          )}

          {confirmButtonText && (
            <Button ml={6} variant="primary" onClick={handleConfirmButtonClick}>
              {confirmButtonText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
