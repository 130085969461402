import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from 'routes';

export function AppRoutes(): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/complete" />} />

      {routes.map((route) => (
        <Route key={route.name} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
}
