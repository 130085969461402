import { useCallback, useState } from 'react';

import { useToast } from '@chakra-ui/react';
import {
  prepareWriteContract,
  readContract,
  waitForTransaction,
  writeContract,
} from '@wagmi/core';
import { useAccount } from 'wagmi';

export type TxStep = {
  step: number;
  status: string;
};

export type ViemError = {
  cause: {
    name: string;
  };
};

export const TX_ERRORS = {
  CANCELED_BY_USER: 'TransactionCanceled',
  GENERAL_ERROR: 'TransactionError',
};

export const TX_STATUS = {
  APPROVE_TOKEN: { step: 0, status: 'approveToken' },
  DEAL_SIGNER: { step: 1, status: 'dealSigner' },
  CONFIRM: { step: 2, status: 'confirm' },
  SUCCESS: { step: 3, status: 'success' },
  CANCELED: {
    step: 3,
    status: 'canceled',
  },
  ERROR: { step: 3, status: 'error' },
};

export function useContractFunction() {
  // State
  const [isTxSuccess, setIsTxSuccess] = useState<boolean | null>(null);

  // Hooks
  const { isConnected } = useAccount();

  const toast = useToast({
    position: 'top',
    duration: 5000,
    isClosable: true,
  });

  // Functions
  const onFunctionSuccess = useCallback(async () => {
    setIsTxSuccess(false);

    toast({
      title: 'Transaction successfully executed',
      status: 'success',
    });

    setIsTxSuccess(true);
  }, [toast]);

  const onFunctionError = useCallback(
    (error: any) => {
      console.dir(error);

      if (!isConnected) {
        toast({
          title: 'You must connect your wallet',
          status: 'error',
        });
      } else if (
        (error as ViemError).cause.name === 'UserRejectedRequestError'
      ) {
        throw new Error(TX_ERRORS.CANCELED_BY_USER);
      } else {
        toast({
          title: error?.name || error,
          description: 'Please check the console for more info.',
          status: 'error',
        });

        throw new Error(TX_ERRORS.GENERAL_ERROR);
      }
    },
    [isConnected, toast]
  );

  const runContractRead = useCallback(
    async (functionConfig: any) => {
      try {
        const data = await readContract(functionConfig);

        return data;
      } catch (error) {
        onFunctionError(error);
      }

      return null;
    },
    [onFunctionError]
  );

  const runContractWrite = useCallback(
    async (functionConfig: any) => {
      try {
        const { request } = await prepareWriteContract(functionConfig);

        const { hash } = await writeContract(request);

        await waitForTransaction({ hash, confirmations: 2 });

        onFunctionSuccess();
      } catch (error) {
        onFunctionError(error);
      }
    },
    [onFunctionError, onFunctionSuccess]
  );

  return {
    isTxSuccess,
    onFunctionError,
    onFunctionSuccess,
    runContractRead,
    runContractWrite,
  };
}
