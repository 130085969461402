import { ReactElement } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { WagmiProvider } from 'contexts';
import customTheme from 'theme';

import { PageLayout } from 'components/PageLayout';

import { AppRoutes } from './AppRoutes';

export function App(): ReactElement {
  return (
    <ChakraProvider theme={customTheme}>
      <WagmiProvider>
        <PageLayout>
          <AppRoutes />
        </PageLayout>
      </WagmiProvider>
    </ChakraProvider>
  );
}
