import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  graphGetProject,
} from 'api/services';
import { SearchParams } from 'routes';
import { ProjectResult } from 'api/providers/graph/queries/getProject.query';


export function useProject() {
  // Hooks
  const [searchParams] = useSearchParams();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [project, setProject] = useState<ProjectResult['project'] | any>(
    {}
  );
 
  // Methods
  const getProjectId = useCallback(() => {
    const projectId = searchParams.get(SearchParams.PROJECT_ID);

    setProjectId(projectId);
  }, [searchParams, setProjectId]);

  const fetchProject = useCallback(async () => {
    if (projectId) {
      try {
        setIsLoading(true);

        const { data: graphProject } = await graphGetProject(projectId);

        setProject(graphProject);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [projectId]);

  // Effects
  useEffect(() => {
    getProjectId();
  }, [getProjectId]);

  return {
    project,
    isLoading,
    projectId,
    fetchProject,
  };
}
