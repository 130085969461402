import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import { TxStep, TX_STATUS } from 'hooks/useContractFunction';

type Props = {
  transactionStatus: TxStep;
};

type StepState = 'loading' | 'error' | undefined;

type TxResult = {
  label: string;
  description: string;
};

export function TxStepper({ transactionStatus }: Props): ReactElement {
  // State
  const [stepState, setStepState] = useState<StepState>(undefined);

  // Hooks
  const { activeStep, setStep } = useSteps({
    initialStep: transactionStatus.step,
  });

  // Helpers
  const checkIfStepCompleted = useCallback(
    (stepIndex) =>
      (transactionStatus.status !== TX_STATUS.CANCELED.status &&
        transactionStatus.status !== TX_STATUS.ERROR.status &&
        stepIndex < transactionStatus.step) ||
      (transactionStatus.status === TX_STATUS.SUCCESS.status &&
        stepIndex <= transactionStatus.step),
    [transactionStatus]
  );

  // Constants
  const txResultStep = useMemo((): TxResult => {
    if (transactionStatus.status === TX_STATUS.CANCELED.status) {
      setStepState('error');

      return {
        label: 'Canceled',
        description: 'Transaction Canceled',
      };
    }

    if (transactionStatus.status === TX_STATUS.ERROR.status) {
      setStepState('error');

      return {
        label: 'Error',
        description: 'Transaction Error',
      };
    }

    return {
      label: 'Receiving USDC',
      description: 'Transaction Successful',
    };
  }, [transactionStatus.status]);

  const steps = [
    {
      index: 0,
      label: 'Approve token',
      description: 'You need to approve the spending cap for this token.',
    },
    {
      index: 1,
      label: 'Sign disclaimer',
      description: 'Please sign the disclaimer.',
    },
    {
      index: 2,
      label: 'Sending project tokens',
      description: '',
    },
    {
      index: 3,
      label: txResultStep.label,
      description: '',
    },
  ];

  const shouldDisplayPendingState =
    transactionStatus.status === TX_STATUS.APPROVE_TOKEN.status ||
    transactionStatus.status === TX_STATUS.DEAL_SIGNER.status ||
    transactionStatus.status === TX_STATUS.CONFIRM.status;

  // Effects
  useEffect(() => {
    setStep(transactionStatus.step);
  }, [transactionStatus, setStep]);

  // Components
  return (
    <Flex p={7} flexDir="column" width="100%">
      <Steps
        orientation="vertical"
        colorScheme="blue"
        activeStep={activeStep}
        state={stepState}
      >
        {steps.map(({ index, label, description }) => (
          <Step
            key={index}
            label={label}
            isCompletedStep={checkIfStepCompleted(index)}
          >
            {shouldDisplayPendingState ? (
              <Box>
                <Text pb={1} width="100%" fontSize="sm" textAlign="left">
                  {description}
                </Text>

                <Flex
                  my={3}
                  px={3}
                  py={2}
                  width="100%"
                  borderRadius="sm"
                  bgColor="gray.200"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={4}
                >
                  <Spinner size="sm" />
                  Processing...
                </Flex>
              </Box>
            ) : (
              <Box>
                <Text
                  fontSize="sm"
                  width="100%"
                  paddingBottom="4px"
                  textAlign="left"
                >
                  {txResultStep.description}
                </Text>
              </Box>
            )}
          </Step>
        ))}
      </Steps>
    </Flex>
  );
}
