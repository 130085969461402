import { mathjs } from './math';

export const withSecondsSymbol = (value: string) =>
  `${value} ${value === '1' ? 'second' : 'seconds'}`;

export const withPercentagesSymbol = (value: number) => `${value}%`;

export const withTokensSymbol = (value: string) => `${value} tokens`;

export const replaceNonDigits = (value: string) => value.replace(/[^\d.]/g, '');

export const websiteUrlValidationRegex =
  /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~#?&\/=]*|)$/;

export const addDecimals = (
  value: string,
  numberOfDecimals: number
): string => {
  const valueWithDecimals = mathjs
    .chain(mathjs.bignumber(value))
    .multiply(mathjs.pow(10, numberOfDecimals))
    .format({ notation: 'fixed' })
    .done();

  return valueWithDecimals;
};

export const subtractDecimals = (
  value: string,
  numberOfDecimals: number
): string => {
  const valueWithoutDecimals = mathjs
    .chain(mathjs.bignumber(value))
    .divide(mathjs.pow(10, numberOfDecimals))
    .format({ notation: 'fixed' })
    .done();

  return valueWithoutDecimals;
};

export const toLocaleStringWithCustomDecimals = (
  value: string = '0',
  minimumFractionDigits: number,
  maximumFractionDigits: number
) => {
  const valueWithTruncatedDecimals = mathjs
    .floor(mathjs.bignumber(value), maximumFractionDigits)
    .toString();

  const formattedValue = Number(valueWithTruncatedDecimals).toLocaleString(
    'en-US',
    {
      minimumFractionDigits,
      maximumFractionDigits,
    }
  );

  return formattedValue;
};
