import { Box, Flex } from '@chakra-ui/react';

import srcColonyLogo from 'assets/images/colony-logo.svg';

import { SidebarLinks } from './SidebarLinks';
import { SidebarFooter } from './SidebarFooter';

export function SidebarContent({ ...props }) {
  return (
    <Flex
      h="full"
      bg="white"
      pos="fixed"
      flexDir="column"
      borderRight="1px"
      justifyContent="space-between"
      borderRightColor="gray.200"
      w={{ base: 'full', md: 60 }}
      {...props}
    >
      <Box px={3}>
        <Box m={8} mb={12}>
          <img src={srcColonyLogo} alt="Colony" />
        </Box>

        <SidebarLinks />
      </Box>

      <SidebarFooter />
    </Flex>
  );
}
