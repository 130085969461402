import { ReactText } from 'react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react';

interface Props extends FlexProps {
  icon: IconType;
  path: string;
  children: ReactText;
}

export function SidebarLink({ icon, path, children }: Props) {
  // Hooks
  const location = useLocation();

  // Constants
  const isActiveRoute = location.pathname.includes(path);

  return (
    <Link
      w="full"
      href={path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        py={3}
        px={4}
        align="center"
        cursor="pointer"
        color="gray.600"
        borderRadius="lg"
        fontWeight="semibold"
        boxShadow={isActiveRoute ? 'lg' : 'none'}
        _hover={{
          cursor: 'pointer',
        }}
      >
        {icon && (
          <Flex
            p={2}
            mr={4}
            bgColor={isActiveRoute ? 'purple.500' : 'gray.100'}
            borderRadius="lg"
            alignItems="center"
          >
            <Icon
              as={icon}
              color={isActiveRoute ? 'white' : 'purple.500'}
              fontSize={20}
            />
          </Flex>
        )}
        {children}
      </Flex>
    </Link>
  );
}
